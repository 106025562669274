html {
  font-size: 14px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  background-color: #000000;
}

.App {
  margin: 0 auto;
  display: block;
}

.mainContainer {
  max-width: 100%;
  margin: auto;
  /* min-height: 50em; */
}

.button.MuiButtonBase-root {
  padding: 0px 7px 0px 7px;
  text-transform: none !important;
  /* font-size: 0.933rem; */
  /* font-weight: 400; */
  line-height: 1.75;
  text-align: center;
  border-radius: 4px !important;
  display: inline-block;
  /* font-family: "PT Sans" !important; */
}
.button.disabled,
button:disabled {
  cursor: not-allowed !important;
}
.button:hover {
  text-decoration: none;
}

/* .outlined.secondary-light {
  border-color: #1f8299;
  color: #1f8299;
  border-style: solid;
  border-width: 1px;
  background-color: white;
} */
/* .outlined.secondary-light:hover {
  border-color: #8db2c2;
  background-color: #dadad9;
} */

/* .mainContainer h2 {
  font-size: 2rem;
  color: #1f8299;
  padding-top: 1em;
  text-align: center;
  margin-bottom: 2em;
} */

.button.plain {
  min-height: 10px;
  padding: 4px 7px 4px 7px;
  color: white;
  font-size: 0.975rem;
  display: inline-block;
  /* font-family: "PT Sans", sans-serif; */
  min-width: 16px;
}

.button.plain.primaryMain {
  background-color: #1f8299;
}

.button.plain.bg-danger {
  background-color: #b91918;
}

.button.plain i {
  color: white;
  padding-right: 5px;
}

.button.plain:hover,
.button.plain:focus {
  background-color: #8db2c2 !important;
}

.dialog-login {
  max-width: 500px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  overflow: hidden;
}
