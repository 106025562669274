.annotation {
  display: flex;
  flex-direction: column;
}

.annotation .search {
  background-color: #2b2a33;
  border-radius: 20px;
}

.annotation .footer {
  position: fixed;
  bottom: 0;
  width: 94vw;
  padding: 10px;
  text-align: center;
}

.annotation .footer .pagination {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  width: 400px;
  color: #ececf5;
  background-color: #2b2a33;
  border-radius: 20px;
  z-index: 1;
  bottom: 0.5em;
}

.annotation .footer .pagination button:disabled svg,
.annotation .footer .pagination button[disabled] svg {
  color: #666666;
}

.annotation .button.first {
  margin-bottom: 5px;
}

.annotation .player {
  border-radius: 10px;
}

.annotation .bt-back {
  text-transform: none;
  color: #ececf5;
  font-weight: 600;
}

.annotation .export {
  margin: 0;
  position: relative;
}
.annotation .buttonprogress {
  color: #1f8299;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.autocomplete {
  margin-bottom: 1.5em;
}

.autocomplete .MuiOutlinedInput-root {
  border-bottom: 1px solid #fbfbfe;
  border-left: 1px solid #fbfbfe;
  border-right: 1px solid #fbfbfe;
}

.divider {
  background-color: #fbfbfe;
}

.sample-drag-bar {
  flex-shrink: 0;
  width: 5px;
  margin: 0;
  border: none;
  background-color: #d1d5db;
  cursor: col-resize;
  transition: background-color 0.15s 0.15s ease-in-out;
}

.option-form,
.timecode-form {
  border-left: 1px solid #454553;
  border-right: 1px solid #454553;
  border-bottom: 1px solid #454553;
}

.note-switch {
  color: #fbfbfe;
}

@media only screen and (max-height: 880px) {
}
