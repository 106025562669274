.stepper {
  color: #fbfbfe;
  background-color: #42414d;
  border-radius: 20px;
  padding: 1em;
}

.stepper .MuiStepLabel-label.Mui-active {
  color: #fddbca;
}
