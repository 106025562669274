.div-list {
  width: 100%;
  background-color: #2b2a33;
  color: #f3eeee;
  margin: 1em 0 1em 0;
  padding: 1em;
  border-radius: 20px;
  display: block;
}

.div-list .transcription {
  width: 100%;
  background-color: #42414d;
  color: #fbfbfe;
  display: flex;
  padding: 5px;
  line-height: 1.5em;
  font-size: 1.1em;
  max-height: 400px;
  overflow-y: scroll;
}

.div-list .reformulation {
  width: 100%;
  background-color: #fbfbfe;
  color: #1f1f20;
  display: flex;
  padding: 5px;
  line-height: 1.5em;
  font-size: 1.1em;
  max-height: 400px;
  overflow-y: scroll;
}

.div-list .text-updated {
  border-color: rgb(201, 0, 0);
}

.div-list .text :focus-visible {
  border-color: rgb(201, 0, 0);
}

.div-list .bt-update {
  float: right;
  color: #f3eeee;
  background-color: #0d587a;
}
.div-list .bt-update:hover {
  background-color: #093f58;
}

.bt-resume {
  margin-bottom: 1em;
}

.bt-menu {
  text-transform: none;
  color: #fff;
  background-color: #1976d2;
}

.bt-action {
  text-transform: none;
  background-color: #fff;
  color: #cfc5c5;
  font-size: 14px;
}

.bt-action.Mui-selected {
  background-color: #c2e0eb;
  color: #c5bbbb;
}

.bt-action.Mui-selected:hover .bt-action:hover {
  background-color: #f5f5f5;
  color: #d3d3d3;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #c2e0eb;
}

.div-list .bt-delete {
  float: right;
  background-color: #b91918;
  color: #f3eeee;
}

.div-list .bt-delete:hover {
  background-color: rgb(78, 8, 8);
}

.div-list .speaker-filter {
  width: 150px;
  margin-bottom: 1.5em;
  background-color: #42414d;
  border-radius: 10px;
  color: #fbfbfe;
}

.div-list .speaker-filter input,
.MuiIconButton-label {
  color: #fbfbfe;
}

.div-list .speaker-filter label {
  color: #b8b8bb;
}

.div-list .speaker-filter .MuiInput-underline {
  border-bottom: 1px solid #fbfbfe;
}

.div-list .point {
  width: 300px;
  margin-bottom: 1.5em;
  background-color: #42414d;
  border-radius: 10px;
}
.div-list .point-changed {
  border-bottom: 1px solid rgb(201, 0, 0);
}
.div-list .MuiAutocomplete-input {
  height: 10px;
}

.div-list .point p {
  color: #b8b8bb;
}

.div-list .point-annotation {
  display: flex;
}

.div-list .add-point {
  font-size: 12px;
  text-decoration: none;
  text-transform: none;
  display: block;
  color: #b8b8bb;
}

.div-list .point-filter {
  display: none;
}

.div-list .point-filter input,
.MuiTypography-colorTextSecondary,
.MuiIconButton-label {
  color: #fbfbfe;
}

.div-list .point-filter label {
  color: #b8b8bb;
}

.div-list .point-filter .MuiInput-underline {
  border-bottom: 1px solid #fbfbfe;
}

.div-list .subpoint-filter {
  display: none;
  margin-left: 1em;
}

.div-list .add-subpoint {
  display: block;
  font-size: 12px;
  text-decoration: none;
  text-transform: none;
  color: #b8b8bb;
}

.text-label {
  color: #fbfbfe;
}
.text-label-re {
  color: #fddbca;
  font-weight: 600;
}

.reformulation-switch {
  color: #fbfbfe;
  font-size: 10px;
}

@media only screen and (max-height: 880px) {
  .div-list .point {
    width: 200px;
  }
}
