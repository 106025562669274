#userSpace_submenu .MuiPaper-root {
  width: 137px;
  margin-left: -1px;
  background-color: transparent;
  /* background-color: #2b2a33; */
}
#admin_submenu .MuiPaper-root {
  margin-left: -15px;
}

#admin_submenu ul {
  background-color: white;
}
#userSpace_submenu ul {
  border-left: 1px solid #1f8299;
  border-right: 1px solid #1f8299;
  border-bottom: 1px solid #1f8299;
  margin: 1px 1px 1px 1px;
  background-color: white;
}
#userSpace_submenu li,
#admin_submenu li {
  display: block;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
}
#userSpace_submenu li:hover,
#admin_submenu li:hover {
  background-color: #c7d7e1;
}
#userSpace_submenu li a,
#admin_submenu li a,
#userSpace_submenu li {
  color: #1f8299;
  font-family: "PT Sans", sans-serif !important;
  font-size: 0.95rem;
}

.closeButton {
  position: absolute !important;
  right: 5px !important;
  cursor: hand;
}
.closeButton:hover {
  background: transparent;
}
.closeButton .fas {
  color: #1f8299;
}
.closeButton .fas:hover {
  color: #b91918;
}

.logout .fas fa-sign-out-alt {
  color: cornflowerblue;
}

.logout {
  color: #d50000;
}

.title {
  display: inline-block;
  width: 90%;
  font-family: Georgia, serif;
  font-size: 2em;
  text-align: center;
  margin: auto;
}

/* display: "inline-block",
        fontSize: '2em',
        textAlign: "center",
        margin: 'auto',
        width: '90%',
        fontFamily: 'Roboto,"Helvetica Neue",sans-serif', */
