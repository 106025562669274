.dialog .MuiDialog-paper {
  color: #ececf5;
  background-color: #606068;
  width: 100%;
}

.text-field {
  width: 50%;
}

.text-field input,
.MuiFormLabel-root,
.MuiSelect-select {
  color: #fbfbfe;
}

.text-field .MuiInput-underline {
  border-bottom: 1px solid #fbfbfe;
}

.MuiFormLabel-root {
  color: #fbfbfe;
}

.radio-group .radio-label .Mui-checked {
  color: #fbfbfe;
}
