.div-list {
  width: 100%;
  background-color: #2b2a33;
  color: #f3eeee;
  margin: 1em 0 1em 0;
  padding: 1em;
  border-radius: 20px;
}

.div-list .bt-back {
  text-transform: none;
  color: #ececf5;
  font-weight: 600;
}

.div-list .main {
  margin-top: 1em;
}

.div-list .export {
  margin: 0;
  position: relative;
}

.div-list .text {
  width: 100%;
  background-color: #42414d;
  color: #fbfbfe;
  display: flex;
  padding: 5px;
  line-height: 1.5em;
  font-size: 1.1em;
}

.div-list .text.text-updated {
  border-color: rgb(201, 0, 0);
}

.div-list .text :focus-visible {
  border-color: rgb(201, 0, 0);
}

.div-list .bt-update {
  float: right;
  /* text-decoration: none; */
  /* text-transform: none; */
  background-color: #0d587a;
  /* font-size: 0.975rem; */
  /* width: 8em; */
}
.bt-resume {
  margin-bottom: 1em;
}

.bt-menu {
  text-transform: none;
  color: #fff;
  background-color: #1976d2;
}

.bt-action {
  text-transform: none;
  background-color: #fff;
  color: #cfc5c5;
  font-size: 14px;
}

.bt-action.Mui-selected {
  background-color: #c2e0eb;
  color: #c5bbbb;
}

.bt-action.Mui-selected:hover .bt-action:hover {
  background-color: #f5f5f5;
  color: #d3d3d3;
}

.MuiMenuItem-root.Mui-selected {
  background-color: #c2e0eb;
}

.div-list .bt-delete {
  float: right;
  background-color: #b91918;
  /* color: white; */
  /* text-decoration: none; */
  /* text-transform: none; */
  /* font-size: 0.975rem; */
  /* width: 8em; */
}

.div-list .speaker-filter {
  width: 150px;
  margin-bottom: 1.5em;
  /* background-color: #42414d; */
  color: #fbfbfe;
}

.div-list .speaker-filter input,
.MuiIconButton-label {
  color: #fbfbfe;
}

.div-list .speaker-filter label {
  color: #b8b8bb;
}

.div-list .speaker-filter .MuiInput-underline {
  border-bottom: 1px solid #fbfbfe;
}

.div-list .point {
  width: 300px;
  margin-bottom: 1.5em;
}

.div-list .point-annotation {
  display: flex;
}

.div-list .add-point {
  display: block;
}

.div-list .point-filter {
  display: none;
}

.div-list .point-filter input,
.MuiTypography-colorTextSecondary,
.MuiIconButton-label {
  color: #fbfbfe;
}

.div-list .point-filter label {
  color: #b8b8bb;
}

.div-list .point-filter .MuiInput-underline {
  border-bottom: 1px solid #fbfbfe;
}

.div-list .subpoint-filter {
  display: none;
  margin-left: 1em;
}

.div-list .add-subpoint {
  display: block;
}

.text-label {
  color: #fbfbfe;
}
.text-label-re {
  color: #fddbca;
  font-weight: 600;
}

.reformulation-switch {
  color: #fbfbfe;
  font-size: 10px;
}

@media only screen and (max-height: 880px) {
  .div-list .point {
    width: 200px;
  }
}
