.table-head th {
  color: #fddbca;
  font-size: 1.2em;
  background-color: #2b2a33;
  font-weight: 600;
}

.table-body {
  color: #fbfbfe;
  background-color: #42414d;
}

.table-row td {
  color: #fbfbfe;
  font-size: 1em;
}

.table-data .list-indices {
  width: 150px;
  margin-bottom: 1.5em;
  color: #fbfbfe;
  background-color: #2b2a33;
  border-radius: 5px;
}

.table-data .list-indices input,
.MuiIconButton-label {
  color: #fbfbfe;
}

.table-data .list-indices label {
  color: #b8b8bb;
}

.table-data .list-indices .MuiInput-underline {
  border-bottom: 1px solid #fbfbfe;
}
