.add-info .previewBt {
  text-decoration: none;
  text-transform: none;
  color: white;
  border: 2px solid;
  border-color: #a1c1ca;

  font-size: 0.975rem;
}

.MuiAutocomplete-root {
  width: 25vw;
}

.list-indices input,
.MuiIconButton-label {
  color: #fbfbfe;
}

.list-indices .MuiOutlinedInput-notchedOutline {
  border-bottom: 1px solid #fbfbfe;
  height: 50px;
}

.list-indices label {
  color: #b8b8bb;
}

.list-indices .MuiInput-underline {
  border-bottom: 1px solid #fbfbfe;
}

.MuiTextField-root {
  width: 25vw;
  color: #fbfbfe;
}

#title,
#select {
  color: #fbfbfe;
}

#title-label,
#select-label {
  color: #b8b8bb;
}

.MuiTextField-root .MuiInput-underline {
  border-bottom: 1px solid #fbfbfe;
  max-width: 350px;
}

.time-input {
  width: 100px;
  /* margin-left: 1em; */
}

.input-note {
  width: 25vw;
  color: #fbfbfe;
  background-color: rgba(0, 0, 0, 0.09);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px;
}

#outlined-adornment-note::placeholder {
  color: #b8b8bb;
}

.stepContent {
  margin-top: 1em;
}

.validateBt {
  color: #fff;
  background-color: #1f8299;
  font-size: 1.2rem;
  border: 0;
  border-radius: 3px;
  padding: 5px 1em;
  text-transform: none;
  text-decoration: none;
  margin: 4em 4em;
}

.date-picker {
  width: 25vw;
  color: #fbfbfe;
  background-color: rgba(0, 0, 0, 0.09);
  padding: 1em;
}

.date-label {
  color: #b8b8bb;
}
