.search-component .results-lists .noResults {
  font-size: 10px;
  margin-bottom: 2px;
  color: #b91918;
  text-align: center;
}

.search-component .results-lists .resultStats {
  font-size: 11px;
  text-align: center;
  font-weight: 500;
  margin-left: 10%;
}

.search-component .text-search .css-1qtpd4b-InputGroup {
  /* width: 18vw; */
  border-radius: 10px;
  box-shadow: 0;
}
.search-component .text-search .input {
  border-radius: 20px;
  background-color: #42414d;
  color: #fbfbfe;
}

.search-component .text-search .list {
  position: absolute;
  background-color: #f2f8f3;
  width: 94%;
  margin: auto;
  z-index: 10;
}

.search-component .multi-list {
  color: #fbfbfe;
}

.search-component .multi-list .select {
  border-radius: 20px;
  background-color: #42414d;
  color: #fbfbfe;
}

.search-component .multi-list .count {
  color: #4fc3f7;
}

.search-component .multi-list .list {
  font-size: 12px;
  color: #42414d;
  border-radius: 10px;
}

.search-component .multi-list .list input {
  background-color: #ececf5;
}

.search-component .selected-filters {
  text-align: center;
}

.search-component .selected-filters .button {
  background-color: #ececf5;
  justify-content: center;
  border-radius: 10px;
}

.tooltip {
  border: 1px solid #2e5d81;
  border-radius: 5px;
  font-size: 0.9rem;
  padding: 1em;
  max-width: 22em;
  background-color: #42414d;
  color: #fbfbfe;
}

.tooltip .close-tootlip {
  float: right;
  color: #ffccbc;
}

.help-button {
  cursor: help;
  color: #ececf5;
}
