.login {
  width: 100%;
  display: block;
  padding: 5px;
  background-color: #fff;
  text-align: center;
}

.login .logout {
  text-transform: none;
  margin-top: 2em;
}

.login .user-name {
  font-weight: 600;
  font-size: 16px;
}

.login .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 2px;
}

.login .icon {
  background-color: #e4e1ff;
  color: #1f8299;
  font-size: 40px;
}

.login .text {
  font-size: 1.5em;
  color: #166475;
  font-weight: 600;
}

.login .input {
  margin: 10px;
  width: 300px;
}
.login #email-label #password-label {
  color: #17171b;
}

.login .MuiFormLabel-root {
  color: #858585;
}
.login .MuiFormLabel-root.Mui-focused,
.MuiInput-underline:after {
  color: #5c5c8b;
  border-bottom: 0px solid #ceced1;
}
